import React, { useState } from 'react';
import Logo from '../Assets/Logo.svg';
import {BsCart2} from 'react-icons/bs';
import {HiOutlineBars3} from 'react-icons/hi2';
import {Box, Drawer, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import List from "@mui/material/List";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
/* importa navegação na landing page */
import {Link} from 'react-scroll';



const Navbar = () => {
    const [openMenu, setOpenMenu] = useState(false)
    const menuOptions = [
        {
            text: "Home",
            icon: <HomeIcon/>
        },
        {
            text: "Alimentação",
            icon: <InfoIcon/>
        },
        {
            text: "Depoimentos",
            icon: <CommentRoundedIcon/>
        },
        {
            text: "Contatos",
            icon: <PhoneRoundedIcon/>
        },
        {
            text: "Pedir",
            icon: <ShoppingCartRoundedIcon/>
        },
    ];

    return (
        <nav className='navbar-full'>
            <div className='nav-logo-container'>
                <img src={Logo} alt=""/>
            </div>
            <div className='navbar-links-container'>
            <a href="">
                    <Link
                        style = {{padding: '0px', margin: '0',}}
                        activeClass='activate'
                        to='Home'
                        span={true}
                        smooth={true}
                    >Home</Link>
                </a>
                <a href="">
                    <Link
                        style = {{padding: '0px', margin: '0',}}
                        activeClass='activate'
                        to='Alimentação'
                        span={true}
                        smooth={true}
                    >Alimentação</Link>
                </a>
                <a href="">
                    <Link
                        style = {{padding: '0px', margin: '0'}}
                        activeClass='activate'
                        to='Depoimentos'
                        span={true}
                        smooth={true}
                    >Depoimentos</Link>
                </a>
                <a href="">
                    <Link
                        style = {{padding: '0px', margin: '0'}}
                        activeClass='activate'
                        to='Contatos'
                        span={true}
                        smooth={true}
                    >Contatos</Link>
                </a>
                <a href="">
                    <BsCart2 className='navbar-cart-icon'/>
                </a>
                <button className='primary-button'>Pedir agora</button>
            </div>
            <div className='navbar-menu-container'>
                <HiOutlineBars3 onClick={() => setOpenMenu(true)}/>
            </div>
            <Drawer open = {openMenu} onClose={() => setOpenMenu(false)} anchor="right">
                <Box 
                    sx={{width: 180}}
                    role="presentation"
                    onClick = {() => setOpenMenu(false)}
                    onKeyDown = {() => setOpenMenu(false)}
                >
                    <List>
                        {menuOptions.map((item) => (
                            <ListItem key={item.text} disablePadding>
                                <ListItemButton>
                                    <Link
                                        style = {{padding: '0px', margin: '0',alignItems: 'center', justifyContent: 'center' }}
                                        activeClass='activate'
                                        to={item.text}
                                        span={true}
                                        smooth={true}
                                    >{item.icon} {item.text}</Link>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </nav>
    );
}

export default Navbar;
